import React, { FormEvent, useEffect, useState } from "react"
import { Accordion, Container, Row, Col, Image, Button, Form } from "react-bootstrap"
import { Contract } from "web3-eth-contract"
import ExamplePunk from "../../../images/SampleZoo.png"
import { isMobile } from "../../../utils/device"
import MetaMaskConnectButton from "../../buttons/MetaMaskConnectButton"
import ZooBanner from "../../../images/NOOBZOO_BANNER.png"

const styles = require("./zoo.module.scss")

type ZooProps = {
  accountAddress: string | null
  contract: Contract
  zooContract: Contract
  getAndSetAccount: Function
}

const PUNKS_SUPPLY_LIMIT: number = 5000;

/**
 * Zoo page.
 */
const Zoo: React.FC<ZooProps> = ({ accountAddress, contract, zooContract, getAndSetAccount }) => {
  // General state
  const [isLoading, setLoading] = useState(false);
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const [transactionUrl, setTransactionUrl] = useState("");

  // Punks contract state
  const [currentSupply, setCurrentSupply] = useState<number>(0);
  const [mintPrice, setMintPrice] = useState<number>(0);
  const [isSaleActive, setIsSaleActive] = useState<boolean>(false);

  // Form state
  const [numPunksToBuy, setNumPunksToBuy] = useState<string>("1");

  // Function to actually do the minting
  const mintPunks = () => {
    if (isSaleActive) {
      return zooContract.methods.publicMintPricePerNft().call().then((price: number) => {
        const numPunksBuying: number = parseInt(numPunksToBuy)
        return zooContract.methods.publicMintTokens(numPunksBuying).send(
          {
            from: accountAddress,
            value: price * numPunksBuying,
          })
          .on('transactionHash', (hash: string) => {
            setTransactionUrl("https://etherscan.io/tx/" + hash);
        })
      })
    } else {
      setLoading(false);
    }
  }

  // Punks contract state
  useEffect(() => {
    if (isLoading) {
      mintPunks().then(() => {
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        setShouldShowError(true);
      });
    }

    if (zooContract && accountAddress) {
      zooContract.methods.totalSupply().call().then((supply: number) => {
        setCurrentSupply(supply);
      })

      zooContract.methods.publicMintPricePerNft().call().then((price: number) => {
        setMintPrice(price / 1000000000000000000);
      })

      zooContract.methods.isPublicMintingActive().call().then((isActive: boolean) => {
        setIsSaleActive(isActive);
      })
    }
  }, [zooContract, accountAddress, isLoading]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
  }

  const hasSaleStarted = isSaleActive;
  const remainingPunks = String(PUNKS_SUPPLY_LIMIT - currentSupply);
  const isSoldOut = ((PUNKS_SUPPLY_LIMIT === currentSupply) && hasSaleStarted);

  const { ethereum } = (typeof window !== 'undefined') ? window : { ethereum: null };
  const isMetaMaskInstalled = ethereum && ethereum.isMetaMask;
  const isMobileAndMetamaskNotInstalled = isMobile() && !isMetaMaskInstalled
  const currentPrice = mintPrice
  const mintLimit = 25

  return (
    <>
      <Container id="punks-intro" className="d-flex align-items-center justify-content-center">
        <Row>
          <Col className="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-center">
            <Row className="d-flex align-items-center justify-content-center">
              <Col className="d-flex align-items-center justify-content-center">
                <Image src={ZooBanner} />
              </Col>
            </Row>

            <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
              <Col className="d-flex align-items-center justify-content-center">
                <h1 className="noobs-sold-out-mint-number">SOLD OUT</h1>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
              <Col>
                <a href="https://opensea.io/collection/noobzoo" target="_blank">
                  <Button
                    variant="primary"
                    className="noob-mint-button"
                    style={{width: "100%"}}
                  >
                    View on OpenSea
                  </Button>
                </a>
              </Col>
            </Row>

          </Col>
          <Col className="col-12 col-lg-6 d-flex align-items-center justify-content-center">
            <Image src={ExamplePunk} className="punks-intro-image" />
          </Col>
        </Row>
      </Container>

      <Container id="faq" className="noob-section" fluid>
        <Row>
          <Col>
            <h1 className={styles.textHeader}>FAQ</h1>
            <Accordion flush>
              <Accordion.Item eventKey="3">
                <Accordion.Header><span className={styles.textQuestion}>First time buyer?</span></Accordion.Header>
                <Accordion.Body>
                  <p>The first step would be setting up your very own wallet using the <a href="https://metamask.io/" target="_blank" className="primary-color-link">metamask plugin</a> on your browser.</p>
                  <p>Once that is done, you're going to need some ETH. You can either buy this directly through metamask, or via an exchange (we recommend  <a href="https://www.gemini.com/share/vkz9wzh8" target="_blank" className="primary-color-link">Gemini</a> but <a href="https://www.coinbase.com/" target="_blank" className="primary-color-link">Coinbase</a> is another option as well).</p>
                  <p>With your ETH in hand, you are going to want to withdraw to your metamask address (if you purchased on an exchange). Then connect your wallet to our site using the option in the navigation bar.</p>
                  <p>Voilà! Just like that you are now ready to buy some Noob Zoo NFTs  - and may we say we are honored to have been your first foray into this wonderful world.</p>
                  <p>If you have any trouble please message the support channel in our Discord server and we will be more than happy to lend a hand.</p>
                  <span>Please note that our team will never contact you to request any personal or other identifying information. Be especially careful with your wallet and NEVER share your seed phrase with anyone.</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header><span className={styles.textQuestion}>Can I purchase a Noob Zoo NFT on a mobile device?</span></Accordion.Header>
                <Accordion.Body>
                  <span>Only through the <a href="https://metamask.io/" target="_blank" className="primary-color-link">MetaMask</a> mobile browser. Otherwise, we only support purchasing on desktop devices via <a href="https://metamask.io/" target="_blank" className="primary-color-link">MetaMask</a>.</span>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header><span className={styles.textQuestion}>Where can I learn more about the contract?</span></Accordion.Header>
                <Accordion.Body>
                  <span>You can view any of the transaction, code, etc. on <a href="https://etherscan.io/address/0x0f2d97060776c8184acA969E9bd2d42B16e7c134" target="_blank" className="primary-color-link">Etherscan</a>.</span>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <br/>
    </>
  )
}

export default Zoo
